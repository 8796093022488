var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row ax mt-1"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "",
      "placeholder": "Search item name or publisher name..."
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.message = $event.target.value;
      }, _vm.debounceSearch]
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" searching... ")])]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Total Buku " + _vm._s(_vm.formatPriceBasic(_vm.totalRows)) + " ")]), _c('div', {
    staticClass: "col-sm-2"
  }, [_c('i', {
    staticClass: "fa fa-refresh",
    on: {
      "click": function ($event) {
        return _vm.actionFetch();
      }
    }
  }, [_vm._v(" reload ")])])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "show-empty": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(premium_idax)",
      fn: function (row) {
        return [!_vm.isSingle(row.item.sku) ? _c('div', [row.item.premium_idax ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" " + _vm._s(row.item.premium_idax) + " ")]) : row.item.approved_md ? _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v(" Waitting approved ")]) : _vm._e()]) : _vm._e()];
      }
    }, {
      key: "cell(single_idax)",
      fn: function (row) {
        return [_vm.isSingle(row.item.sku) ? _c('div', [row.item.single_idax ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" " + _vm._s(row.item.single_idax) + " ")]) : _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v(" Waitting approved ")])]) : _vm._e()];
      }
    }, {
      key: "cell(item_type)",
      fn: function (row) {
        return [_vm.isSingle(row.item.sku) ? _c('span', {
          staticClass: "badge badge-info"
        }, [_vm._v(" Single ")]) : _c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v(" Premium ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticStyle: {
            "width": "7em"
          },
          attrs: {
            "title": _vm.formatDate(row.item.created)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatTimeinfo(row.item.created)) + " ")])];
      }
    }, {
      key: "cell(updated)",
      fn: function (row) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticStyle: {
            "width": "7em"
          },
          attrs: {
            "title": _vm.formatDate(row.item.modified)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatTimeinfo(row.item.modified)) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_vm.isHaveIDAX(row.item) ? _c('b-dropdown', {
          staticClass: "m-2",
          attrs: {
            "split": "",
            "split-variant": "outline-primary",
            "variant": "primary",
            "text": "Actions"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.actionSendOfs(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-send"
        }), _vm._v(" Send Ofs ")])], 1) : _vm._e()];
      }
    }, {
      key: "cell(detail)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-2 btn-success",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" " + _vm._s(row.detailsShowing ? 'Hide' : 'Show') + " ")])];
      }
    }, {
      key: "cell(refresh)",
      fn: function (row) {
        return [_c('button', {
          staticClass: "btn btn-sm",
          attrs: {
            "type": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh",
          on: {
            "click": function ($event) {
              return _vm.checkApproved(row.item.request_body);
            }
          }
        })])];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('pre', [_vm._v(_vm._s(JSON.stringify(row.item.request_body, null, 2)))])];
      }
    }])
  }), _vm.isLoading ? _c('div', {
    staticClass: "text-center justify-content-center"
  }, [_c('b-spinner')], 1) : _vm._e()], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "col-sm-2",
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Search Book "), _c('br')]);
}]

export { render, staticRenderFns }