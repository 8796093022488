<template>
  <div class="row ax mt-1">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <label for="" class="col-sm-2"> Search Book <br /> </label>
            <div class="col-sm-7">
              <input
                type="text"
                name=""
                id=""
                class="form-control"
                placeholder="Search item name or publisher name..."
                @input="debounceSearch"
                v-model="message"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
          </div>
          <div class="form-group row">
            <label for="" class="col-sm-2">
              Total Buku
              {{ formatPriceBasic(totalRows) }}
            </label>
            <div class="col-sm-2">
              <i class="fa fa-refresh" @click="actionFetch()"> reload </i>
            </div>
          </div>
          <b-table
            sticky-header="500px"
            responsive="sm"
            show-empty
            :busy="isLoading"
            :fields="fields"
            hover
            :items="items"
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(premium_idax)="row">
              <div v-if="!isSingle(row.item.sku)">
                <span v-if="row.item.premium_idax" class="badge badge-success">
                  {{ row.item.premium_idax }}
                </span>
                <span
                  v-else-if="row.item.approved_md"
                  class="badge badge-warning"
                >
                  Waitting approved
                </span>
              </div>
            </template>
            <template #cell(single_idax)="row">
              <div v-if="isSingle(row.item.sku)">
                <span v-if="row.item.single_idax" class="badge badge-success">
                  {{ row.item.single_idax }}
                </span>
                <span v-else class="badge badge-warning">
                  Waitting approved
                </span>
              </div>
            </template>
            <template #cell(item_type)="row">
              <span v-if="isSingle(row.item.sku)" class="badge badge-info">
                Single
              </span>
              <span v-else class="badge badge-primary">
                Premium
              </span>
            </template>
            <!-- 
              <template 
              #cell(approved)="row">
              <button v-if="!row.item.approved_md" class="btn btn-sm btn-primary">
                <i class="fa fa-check"></i>
              </button>
              <button v-else class="btn btn-sm btn-success">
                Validated
              </button>
            </template> -->

            <template #cell(created)="row">
              <div v-b-tooltip.hover :title="formatDate(row.item.created)" style="width:7em">
                {{ formatTimeinfo(row.item.created) }}
              </div>
            </template>
            <template #cell(updated)="row">
              <div v-b-tooltip.hover :title="formatDate(row.item.modified)" style="width:7em">
                {{ formatTimeinfo(row.item.modified) }}
              </div>
            </template>
            <template #cell(action)="row">
              <b-dropdown
                v-if="isHaveIDAX(row.item)"
                split
                split-variant="outline-primary"
                variant="primary"
                text="Actions"
                class="m-2"
              >
                <b-dropdown-item
                  variant="primary"
                  @click="actionSendOfs(row.item)"
                >
                  <i class="fa fa-send"></i>
                  Send Ofs
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(detail)="row">
              <b-button
                size="sm"
                @click="row.toggleDetails"
                class="mr-2 btn-success"
              >
                {{ row.detailsShowing ? 'Hide' : 'Show' }}
              </b-button>
            </template>
            <template #cell(refresh)="row">
              <button type="button" class="btn btn-sm">
                <i
                  class="fa fa-refresh"
                  @click="checkApproved(row.item.request_body)"
                ></i>
              </button>
            </template>
            <template #row-details="row">
              <pre>{{ JSON.stringify(row.item.request_body, null, 2) }}</pre>
            </template>
          </b-table>
          <div v-if="isLoading" class="text-center justify-content-center">
            <b-spinner />
          </div>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RegisterItem',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'item_name', label: 'Name' },
        { key: 'product_owner', label: 'Publisher' },
        { key: 'vendor_idax', label: 'Vendor ID' },
        { key: 'premium_idax', label: 'Premium AX' },
        { key: 'single_idax', label: 'Single AX' },
        // { key: 'isbn', label: 'ISBN' },
        { key: 'sku', label: 'SKU' },
        { key: 'item_type', label: 'Item Type' },
        { key: 'published_date', label: 'PublishDate' },
        { key: 'created', label: 'Created', sortable: true },
        //{ key:'approved',label: 'Validasi Data'},
        { key: 'updated', label: 'Updated' },
        { key: 'action', label: 'Action' },
        { key: 'detail', label: 'detail' },
        { key: 'refresh', label: '' },
      ],
      filter: '',
      debounce: null,
      message: '',
      typing: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ax.isLoading,
      isError: (state) => state.ax.isError,
      totalRows: (state) => state.ax.totalRows,
      items: (state) => state.ax.items,
    }),
  },
  mounted() {
    this.actionFetch();
  },
  watch: {
    currentPage: function() {
      this.actionFetch();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Items Request IDAX  | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('ax', ['fetchItem', 'search', 'checkApprovedItem']),
    ...mapActions('ofscatalog', ['sendItem']),
    actionFetch() {
      console.log(this.message);
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        q: this.message,
      };
      if (this.filter.length > 2) {
        payload.q = this.filter;
      }
      this.fetchItem(payload);
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY hh:mm:ss');
    },
    isSingle(sku) {
      return !(sku.substr(0, 6) == 'SC00PG');
    },
    formatTimeinfo(date) {
      return moment(date).fromNow();
    },
    // searchItem() {
    //   this.search({ q: this.filter });
    // },
    async actionSendOfs(item) {
      try {
        let payload = {
          id: parseInt(item.item_id, 10),
        };
        let info = await this.sendItem(payload);
        console.log(info);
        this.$swal({
          toast: 'true',
          title: 'Success',
          text: 'Item has been sent to OFS Catalog',
          icon: 'success',
          timer: 3000,
          timerProgressBar: true,
        });
      } catch (error) {
        console.log(error);
        this.$swal({
          title: 'Error',
          text: 'Item has not been sent to OFS Catalog',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    isHaveIDAX(item) {
      if (item.single_idax || item.premium_idax) {
        return true;
      }
      return false;
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchItem({
          q: this.message,
          page: 1,
          limit: this.perPage,
        });
      }, 600);
    },

    checkApproved(item) {
      this.loadingPopUp();
      try {
        this.checkApprovedItem({ item })
          .then(() => this.alertMsg('Item has been Check approved', 'Success'))
          .catch((err) => {
            if (err.response.status == 422) {
              err.response.data.errors.map((e) =>
                this.alertMsg(e.msg, 'error'),
              );
            } else {
              this.alertMsg(err.response.data.data.message, 'Error');
            }
          });
      } catch (error) {
        console.log(error);
        this.alertMsg('Ops. Something Went Wrong !', 'Error');
      }
    },

    loadingPopUp() {
      this.$swal({
        title: 'Loading',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {},
      });
    },

    alertMsg(message, type) {
      this.$swal({
        toast: 'true',
        title: type,
        icon: type.toLowerCase(),
        text: message,
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    },
  },
};
</script>

<style lang="css" scoped>
.ax {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
input::placeholder {
  font-style: italic;
}
</style>
